import React from 'react';
import Loading from './Loading';
import './LoadingPane.css';

function LoadingPane() {
  return (
    <div className="LoadingPane">
      <div className="LoadingPaneInner">
        <Loading/>
      </div>
    </div>
  );
}

export default LoadingPane;
