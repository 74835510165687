import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from './Sidebar';
import Pagination from './Pagination';
import LoadingPane from '../common/LoadingPane';
import Header from '../common/Header';
import Table from './Table';
import { request, parseParams, encodeQuery, updateQuery } from '../helpers';
import { useFetch, usePrevious } from '../hooks';
import './List.css';
import AddPage from './AddPage';

function List(props) {
  const userId = localStorage.getItem('userId');
  const limit = 15;
  const [status, setStatus] = useState();
  const [selected, setSelected] = useState({});
  const [showAddPage, setShowAddPage] = useState(false);
  const prevSearch = usePrevious(props.location.search);
  let params = parseParams(props.location.search);
  const page = parseInt(params.page) || 1;
  const tag = parseInt(params.tag);
  params.limit = limit;
  params.prefetch = limit;
  const {
    exec, showLoading, loading, data, error, controller,
  } = useFetch(`my_feed?${encodeQuery(params)}`);
  let { total_pages } = data || {};
  let [items, setItems] = useState([]);
  let [unread, setUnread] = useState();
  let [later, setLater] = useState();
  let [err, setErr] = useState();
  const tagsFetch = useFetch('tags');
  let [tags, setTags] = useState([]);
  let [editing, setEditing] = useState({});

  const checkLocationChange = async () => {
    if (prevSearch !== props.location.search) {
      await exec();
      window.scrollTo(0, 0);
      setSelected({});
    }
  };

  useEffect(() => {
    checkLocationChange();
  });

  useEffect(() => {
    tagsFetch.exec();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tagsFetch.data) {
      setTags(tagsFetch.data.list);
    }
  }, [tagsFetch.data]);

  useEffect(() => {
    if (data) {
      setItems(data.items);
      setUnread(data.unread);
      setLater(data.later);
    } else {
      setItems([]);
      setUnread(0);
      setLater(0);
    }
    setStatus(params.status);

    let loc = 'Inbox';
    if (params.status === 'archived') {
      loc = 'Archive';
    } else if (params.status === 'later') {
      loc = 'Later';
    }
    if (data && data.unread) {
      document.title = `(${data.unread}) ${loc}`;
    } else {
      document.title = loc;
    }
  }, [data, params.status]);

  useEffect(() => {
    if (error) {
      setErr(error);
    }
  }, [error]);

  useEffect(() => {
    setEditing({});
  }, [status, tag, page]);

  if (!userId) {
    props.history.push('/login');
    return null;
  }

  const handlePaginationClick = (direction) => {
    const nextPage = direction === 'next' ? page + 1 : page - 1;
    updateQuery(props.history, {page: nextPage});
  };

  const select = (item) => {
    if (item in selected) {
      delete selected[item];
    } else {
      selected[item] = true;
    }
    setSelected({...selected});
  };

  const toggleSelectAll = () => {
    let newSelected = {};
    for (let item of data.items.slice(0, limit)) {
      newSelected[item.id] = true;
    }
    if (JSON.stringify(selected) === JSON.stringify(newSelected)) {
      setSelected({});
    } else {
      setSelected(newSelected);
    }
  };

  const toggleAddPage = () => {
    setShowAddPage(!showAddPage);
  };

  const action = async (actionName, item) => {
    let newItems = items.filter(i => i.id !== item);
    setItems(newItems);
    if (!status) {
      setUnread(unread - 1);
      if (actionName === 'later') {
        setLater(later + 1);
      }
    } else if (status === 'later') {
      setLater(later - 1);
    }
    delete selected[item];
    setSelected(selected);
    if (loading) {
      controller.current.abort();
    }
    try {
      await request(`${actionName}?id=${item}`, { method: 'POST' });
      if (items.length === 1 && total_pages === page && page > 1) {
        handlePaginationClick('prev');
      } else {
        await exec(false);
      }
    }
    catch (e) {
      return setErr(e);
    }
  };

  const bulkAction = async (action) => {
    let ids = [];
    for (let id of Object.keys(selected)) {
      ids.push(parseInt(id));
    }
    if (!ids.length) return;
    await request(`bulk?action=${action}`, {
      method: 'POST',
      body: JSON.stringify({ ids: ids })
    });
    if (items.length === ids.length && total_pages === page && page > 1) {
      handlePaginationClick('prev');
    } else {
      await exec();
      setSelected({});
    }
  };

  const toggleEdit = (item) => {
    if (item.id === editing.id) {
      setEditing({});
    } else {
      let title = item.user_title !== ''? item.user_title: item.title;
      setEditing({id: item.id, title: title});
    }
  };

  const update = (item) => {
    for (let it of data.items) {
      if (it.id === item.id) {
        if (item.title === it.title && it.user_title === '') break;
        if (item.title === it.user_title) break;

        it.user_title = item.title;
        request(
          'update_title',
          {
            method: 'POST',
            body: JSON.stringify({id: item.id, title: item.title})
          }
        );
        break;
      }
    }
  };

  const finishEditing = () => {
    update(editing);
    setEditing({});
  };

  const addTag = async (item, tag) => {
    for (let it of data.items) {
      if (it.id === item.id) {
        it.tags.push(tag);
        await request(
          'update_tags',
          {
            method: 'POST',
            body: JSON.stringify({id: item.id, add: [tag.name]})
          }
        );
        tagsFetch.exec();
        break;
      }
    }
  };

  const removeTag = (item, tag) => {
    for (let it of data.items) {
      if (it.id === item.id) {
        it.tags = it.tags.filter(t => t.id !== tag.id);
        setItems([...data.items]);
        request(
          'update_tags',
          {
            method: 'POST',
            body: JSON.stringify({id: item.id, remove: [tag.name]})
          }
        );
        break;
      }
    }
  };

  const search = (query) => {
    updateQuery(props.history, {page: 1, q: query});
  };

  const isArchived = status === 'archived';
  const isLater = status === 'later';
  return (
    <div className="outer">
      {err && <div className="error">Network error. Please refresh.</div>}
      <Header
        status={status}
        toggleSelectAll={toggleSelectAll}
        bulkAction={bulkAction}
        search={search}
        toggleAddPage={toggleAddPage}
      />
      <Sidebar
        status={status}
        unread={unread}
        later={later}
        tag={tag}
        tags={tags}
        reload={exec}
      />
      {showLoading && <LoadingPane/>}
      {!showLoading &&
        <div className="main">
          <AddPage
            status={status}
            show={(isArchived || isLater) && showAddPage}
            setShow={setShowAddPage}
            reload={exec}
          />
          <Table
            status={status}
            items={items.slice(0, limit)}
            selected={selected}
            select={select}
            action={action}
            tags={tags}
            addTag={addTag}
            removeTag={removeTag}
            editing={editing}
            setEditing={setEditing}
            toggleEdit={toggleEdit}
            finishEditing={finishEditing}
          />
        </div>
      }
      <Pagination
        page={page}
        totalPages={total_pages}
        handlePaginationClick={handlePaginationClick}
        disable={loading}
      />
    </div>
  );
}

export default withRouter(List);
