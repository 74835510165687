import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingPane from '../common/LoadingPane';
import { useFormInput } from '../hooks';
import { useAdminFetch } from './hooks';

function AdminLogin(props) {
  document.title = 'Root login';

  const password = useFormInput('');

  const { exec, showLoading, error} = useAdminFetch(
    'login',
    {
      method: 'POST',
      body: JSON.stringify({password: password.value})
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    await exec();
    props.history.push('/root');
  };

  return (
    <div>
      {showLoading && <LoadingPane/>}
      {!showLoading &&
          <div>
            <div>
              <form onSubmit={ handleSubmit }>
                <input type="password" name="password" placeholder="password" {...password}/>
                <input type="submit" value="Login" />
              </form>
            </div>
            <div>{ error && <div className="error">{error}</div> }</div>
          </div>
      }
    </div>
  );
}

export default withRouter(AdminLogin);
