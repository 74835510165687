import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingPane from '../common/LoadingPane';
import { useFormInput, useFetch } from '../hooks';
import { API_URL } from '../config';

function Feeds() {
  document.title = 'Feeds';

  const newFeed = useFormInput('');
  const newFeedFilters = useFormInput('');

  const { exec, showLoading, loading, data, error } = useFetch('feeds');

  useEffect(() => {
    exec();
  // eslint-disable-next-line
  }, []);

  let filterError = '';
  let filters = newFeedFilters.value;
  if (filters) {
    try {
      filters = JSON.parse(filters);
    } catch (e) {
      filterError = 'Valid JSON in filter needed';
    }
  } else {
    filters = {};
  }

  const newFeedFetch = useFetch(
    'new_feed',
    {
      method: 'POST',
      body: JSON.stringify({
        url: newFeed.value,
        filters: filters,
      })
    }
  );

  const sumbitNewFeed = async (event) => {
    event.preventDefault();
    if (filterError) {
      return;
    }
    await newFeedFetch.exec();
    newFeed.onChange('');
    newFeedFilters.onChange('');
    await exec();
  };

  const handleChange = () => {
    // TODO: implement filter update
  };

  const errorMsg = error || newFeedFetch.error || filterError;

  return (
    <div>
      {showLoading && <LoadingPane/>}
      {!showLoading &&
        <div className="outer">
          <Link to="/">Home</Link>
          {data && Object.keys(data).map(site =>
            <div key={site}>
              {site}
              {Object.keys(data[site]).map(feed =>
                <div key={feed}>
                  {feed}
                  {Object.keys(data[site][feed]).map((filter, i) =>
                    <div key={feed+i}>
                      <div>{filter}</div>
                      <input type="text" value={data[site][feed][filter]} onChange={ handleChange } />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div>
            <a href={API_URL + '/twitter_authorize'}>Connect Twitter</a>
          </div>
          <form onSubmit={ sumbitNewFeed }>
            <input type="text" name="newFeed" {...newFeed} />
            <input type="text" name="newFeedFilters" {...newFeedFilters} />
            <input type="submit" value="Add" disabled={loading} />
          </form>
          <div>{ errorMsg && <div className="error">{errorMsg}</div> }</div>
        </div>
      }
    </div>
  );
}

export default Feeds;
