import React, { useState, useEffect } from 'react';
import { useFetch, useFormInput } from '../hooks';

function AddPage(props) {
  const url = useFormInput('');
  const [error, setError] = useState('');
  let status = props.status;
  if (status === 'archived') {
    status = 'archive';
  }
  let fetch = useFetch('add_news', {
    method: 'POST',
    body: JSON.stringify({url: url.value, status: status}),
  });

  useEffect(() => {
    if (fetch.error) {
      setError(fetch.error);
    }
  }, [fetch]);

  if (!['archived', 'later'].includes(props.status)) {
    return null;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!url.value) {
      setError('No url');
      return;
    }
    await fetch.exec();
    setError('');
    url.onChange('');
    props.setShow(false);
    props.reload();
  };

  return (
    <div>
      {fetch.showLoading && <div>Checking url...</div>}
      {error && <div>{error}</div>}
      <form onSubmit={ handleSubmit } style={props.show? {'display': 'block'}: {'display': 'none'}}>
        <input type="text" placeholder="url" {...url}/>
        <input type="submit" value="Add"/>
      </form>
    </div>
  );
}

export default AddPage;
