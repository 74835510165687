import React from 'react';
import './Pagination.css';

function Pagination(props) {
  const { totalPages, page, disable, handlePaginationClick } = props;

  if (!totalPages || totalPages <= 1) {
    return '';
  }
  return (
    <div className="Pagination">
      <button
        className="Pagination-button"
        disabled={disable || (page <= 1)}
        onClick={() => handlePaginationClick('prev')}
      >
        &larr;
      </button>

      <span className="Pagination-info">
        page <b>{page}</b> / <b>{totalPages}</b>
      </span>

      <button
        className="Pagination-button"
        disabled={disable || (page === totalPages)}
        onClick={() => handlePaginationClick('next')}
      >
        &rarr;
      </button>
    </div>
  );
}

export default Pagination;
