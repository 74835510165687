import React, { useState } from 'react';
import { useFormInput } from '../hooks';
import './Tags.css';


function itemIndexOf(items, item) {
  if (!item) return -1;
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === item.id) {
      return i;
    }
  }
  return -1;
}

function Tags(props) {
  let {item, tags, addTag, removeTag} = props;
  let current = item.tags;
  if (!current) {
    current = [];
  }

  let input = useFormInput('');
  let [selected, setSelected] = useState();
  let [focus, setFocus] = useState(false);

  let suggestions = [];
  if (focus) {
    let currentIds = current.map(c => c.id);
    suggestions = tags.filter(t => !currentIds.includes(t.id));
    if (input.value.length > 0) {
      suggestions = suggestions.filter(t => t.name.startsWith(input.value));
    }
  }

  const onKeyDown = (event) => {
    // ENTER
    if (event.nativeEvent.keyCode === 13) {
      event.preventDefault();
      if (selected) {
        addTagWrap(selected);
        setSelected(null);
      } else {
        let tag = tags.filter(e => e.name === input.value);
        if (tag.length > 0) {
          tag = suggestions.filter(e => e.name === input.value);
          if (tag.length > 0) {
            addTagWrap(tag[0]);
          }
        } else {
          addTagWrap({name: input.value});
        }
      }
    } // UP
    else if (event.nativeEvent.keyCode === 38) {
      event.preventDefault();
      let index = itemIndexOf(suggestions, selected);
      if (index > 0) {
        setSelected(suggestions[index-1]);
      }
    } // DOWN
    else if (event.nativeEvent.keyCode === 40) {
      event.preventDefault();
      let index = itemIndexOf(suggestions, selected);
      if (index < suggestions.length - 1) {
        setSelected(suggestions[index+1]);
      }
    } // ESC
    else if (event.nativeEvent.keyCode === 27) {
      event.preventDefault();
      setFocus(false);
      setSelected(null);
    }
  };

  const addTagWrap = (tag) => {
    input.onChange('');
    addTag(item, tag);
  };

  const removeTagWrap = (tag) => {
    removeTag(item, tag);
  };

  const onSuggestionClick = (e, s) => {
    e.preventDefault();
    addTagWrap(s);
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = (e) => {
    if (e.target === document.activeElement) return;
    setFocus(false);
    setSelected(null);
  };

  const getSuggestionStyle = (s) => {
    return selected && s.id === selected.id? {'background': '#eeeeee'}: {};
  };

  let style;
  if (props.editable) {
    style = {display: 'inline-block'};
  } else {
    style = {display: 'none'};
  }

  return (
    <span className="tagsCont">
      <span>{current.map(t =>
        <span key={t.id}>
          {t.name}
          <button style={style} onClick={() => removeTagWrap(t)}>X</button>
        </span>
      )}</span>
      <span style={style}>
        <input {...input} placeholder="Enter tags..."
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        { suggestions.length > 0 &&
        <div className="suggestions">{suggestions.map(s =>
          <span key={s.id} className="suggestion " style={getSuggestionStyle(s)}
            onMouseDown={(e) => onSuggestionClick(e, s)}
            onMouseOver={() => setSelected(s)}
            onMouseLeave={() => setSelected(null)}>
            {s.name}
          </span>
        )}</div>}
      </span>
    </span>
  );
}

export default Tags;
