import React from 'react';
import LinkExt from '../common/LinkExt';
import { withRouter } from 'react-router-dom';
import { parseParams, encodeQuery } from '../helpers';
import './Sidebar.css';

function renderNum(num) {
  if (num) {
    return ` (${num})`;
  } else {
    return '';
  }
}

function SideBar(props) {
  const { status, unread, later, tag, tags, reload, history } = props;

  const toggleTag = () => {
    let params = parseParams(history.location.search);
    delete params['tag'];
    let loc = history.location.pathname + '?' + encodeQuery(params);
    history.push(loc);
  };

  return (
    <div className="Sidebar">
      <ul className="categories">
        <li className={!status? 'active': ''}>
          <LinkExt to="/" reload={reload}>
            Inbox{ renderNum(unread) }
          </LinkExt>
        </li>
        <li className={status === 'later'? 'active': ''}>
          <LinkExt to="/?status=later" reload={reload}>
            Later{ renderNum(later) }
          </LinkExt>
        </li>
        <li className={status === 'archived'? 'active': ''}>
          <LinkExt to="/?status=archived" reload={reload}>
            Archive
          </LinkExt>
        </li>
      </ul>
      <ul>
        { tags && tags.map((t) =>
          <li key={t.id} className={tag === t.id? 'active': ''}>
            <LinkExt query={{tag: t.id}} reload={toggleTag}>
              {t.name}
            </LinkExt>
          </li>
        ) }
      </ul>
    </div>
  );
}

export default withRouter(SideBar);
