import React from 'react';

function Checkbox(props) {
  return (
    <button onClick={props.onClick}>
      {
        (
          props.checked &&
          <i className="material-icons md-18">
            check_box
          </i>
        ) ||
        <i className="material-icons md-18">
          check_box_outline_blank
        </i>
      }
    </button>
  );
}

export default Checkbox;
