import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import LoadingPane from '../common/LoadingPane';
import { useFormInput, useFetch } from '../hooks';

function Register() {
  document.title = 'Register';

  const email = useFormInput('');
  const password = useFormInput('');
  const [finish, setFinish] = useState(false);

  const {exec, showLoading, loading, error} = useFetch(
    'register',
    {
      method: 'POST',
      body: JSON.stringify({email: email.value, password: password.value}),
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    await exec();
    setFinish(true);
  };

  return (
    <div>
      {showLoading && <LoadingPane/>}
      {!showLoading && !finish &&
          <div>
            <div>
              <form onSubmit={ handleSubmit }>
                <input type="text" name="email" placeholder="email" {...email} />
                <input type="password" name="password" placeholder="password" {...password} />
                <input type="submit" value="Register" disabled={loading}/>
              </form>
            </div>
            <div>{ error && <div className="error">{error}</div> }</div>
          </div>
      }
      {!showLoading && finish &&
        <h1>
          Check your email for confirmation email
        </h1>
      }
    </div>
  );
}

export default withRouter(Register);
