import React from 'react';
import Checkbox from '../common/Checkbox';
import Tags from '../common/Tags';
import './Table.css';


function renderFeedName(item) {
  if (item.type === 'reddit') {
    return `[reddit/${item.site}]`;
  } else if (item.type === 'hn') {
    return '[hn]';
  } else if (item.type === 'www.youtube.com') {
    return `[youtube/${item.site_title}]`;
  } else if (item.type === 'twitter') {
    return `[${item.type}/${item.site_title}]`;
  } else {
    return `[${item.type}]`;
  }
}

function getScoreName(item) {
  if (item.type === 'www.youtube.com') {
    return 'views';
  } else if (item.type === 'twitter') {
    return 'likes';
  } else {
    return 'points';
  }
}

function getCommentsName(item) {
  if (item.type === 'www.youtube.com') {
    return 'reactions';
  } else if (item.type === 'twitter') {
    return 'retweets';
  } else {
    return 'comments';
  }
}

function formatNumber(number) {
  if (number > 1000) {
    return (number / 1000.0).toFixed(1) + 'K';
  } else if (number > 1000000) {
    return (number / 1000000.0).toFixed(1) + 'M';
  } else {
    return number;
  }
}

function getCommentsUrl(item) {
  if (item.type === 'reddit') {
    return `https://www.reddit.com/r/${item.site}/comments/${item.news_id}`;
  } else if (item.type === 'hn') {
    return `https://news.ycombinator.com/item?id=${item.news_id}`;
  } else {
    return '#';
  }
}

function ifComments(item) {
  return ['hn', 'reddit', 'www.youtube.com', 'twitter'].includes(item.type);
}

function getInputSize(title) {
  let size = title.length;
  if (size < 20) {
    size = 20;
  } else if (size > 80) {
    size = 80;
  }
  return '' + size;
}

function tagsStyle(item, editing) {
  if (item.tags.length || editing.id === item.id) {
    return {display: 'block'};
  } else {
    return {display: 'none'};
  }
}

function Table(props) {
  const {
    status, items, selected, select, action, tags,
    editing, setEditing, toggleEdit, finishEditing
  } = props;

  const onChange = (e) => setEditing({id: editing.id, title: e.target.value});

  const onKeyDown = (event) => {
    // ENTER
    if (event.nativeEvent.keyCode === 13) {
      event.preventDefault();
      finishEditing();
    }
  };

  return (
    <ul>
      {items && items.map(item =>
        <li key={item.id} className="item">
          <div>
            { editing.id !== item.id &&
              <a target="_blank" href={item.link} rel="noopener noreferrer">
                {item.user_title !== '' ? item.user_title: item.title}
              </a>
            }
            { editing.id === item.id &&
              <span>
                <input value={editing.title} onChange={onChange} onKeyDown={onKeyDown}
                  size={getInputSize(editing.title)} />

                <button className="editBtn" onClick={ () => finishEditing() }>
                  <i className="material-icons md-14">check</i>
                </button>
                <button className="editBtn" onClick={ () => toggleEdit(item) }>
                  <i className="material-icons md-14">cancel</i>
                </button>
              </span>
            }
            <span className="feedName">{ renderFeedName(item) }</span>
          </div>
          <div className="content">
            {
              item.thumbnail &&
              <p>
                <a target="_blank" href={item.link} rel="noopener noreferrer">
                  <img src={item.thumbnail} alt={item.thumbnail}/>
                </a>
              </p>
            }
            {
              item.description &&
              <div className="desc"
                dangerouslySetInnerHTML={{ __html: item.description}}></div>
            }
            {
              <div style={tagsStyle(item, editing)}>
                tags:
                <Tags
                  key={''+item.id+(editing.id === item.id)}
                  id={'tags' + item.id}
                  editable={editing.id === item.id}
                  item={item}
                  tags={tags}
                  addTag={props.addTag}
                  removeTag={props.removeTag}
                />
              </div>
            }
          </div>
          <div className="controls">
            <Checkbox checked={item.id in selected} onClick={ () => select(item.id) }/>
            { ifComments(item) &&
            <span>
              <span>{formatNumber(item.score)} {getScoreName(item)}</span>
              <a target="_blank" href={getCommentsUrl(item)} rel="noopener noreferrer">
                {formatNumber(item.comments)} {getCommentsName(item)}
              </a>
            </span>
            }
            {
              <button onClick={ () => toggleEdit(item) }>
                <i className="material-icons md-18">edit</i>
              </button>
            }
            {
              !['later', 'archived'].includes(status) &&
              <button onClick={ () => action('later', item.id) }>
                <i className="material-icons md-18">access_time</i>
              </button>
            }
            {
              status !== 'archived' &&
              <button value="A" onClick={ () => action('archive', item.id) }>
                <i className="material-icons md-18">archive</i>
              </button>
            }
            <button onClick={ () => action('remove', item.id) }>
              <i className="material-icons md-18">delete</i>
            </button>
          </div>
        </li>
      )}
    </ul>
  );
}

export default Table;
