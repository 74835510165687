import { API_URL } from './config';

export const handleResponse = async (response) => {
  if (!response) {
    return;
  }
  if (!response.ok && response.status !== 400) {
    const error = {error: response.status};
    throw error;
  }
  const json = await response.json();
  if (response.ok) {
    return json;
  } else {
    const error = {error: json};
    throw error;
  }
};

export const request = async (url, options = {}) => {
  options.credentials = 'include';
  const resp = await fetch(`${API_URL}/${url}`, options);
  return await handleResponse(resp);
};

export const parseParams = search => {
  search = search.slice(1);
  let parts = search.split('&');
  let params = {};
  for (let part of parts) {
    let [name, value] = part.split('=');
    if (name) params[name] = value;
  }
  return params;
};

export const encodeQuery = data => {
  let ret = [];
  for (let d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }
  return ret.join('&');
};

export const updateQuery = (history, data) => {
  let {pathname, search} = history.location;
  let params = parseParams(search);
  for (let k in data) {
    params[k] = data[k];
  }
  history.push(`${pathname}?${encodeQuery(params)}`);
};

export const emit = (event, data) => {
  let e = new CustomEvent(event, { detail: data });
  document.body.dispatchEvent(e);
};

export const subscribe = (event, listener) => {
  document.body.addEventListener(event, listener);
};
