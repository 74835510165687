import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import LoadingPane from '../common/LoadingPane';
import { useFetch, useFormInput } from '../hooks';


function Login(props) {
  document.title = 'Login';

  const email = useFormInput('');
  const password = useFormInput('');

  const {exec, showLoading, loading, data, error} = useFetch(
    'login',
    {
      method: 'POST',
      body: JSON.stringify({email: email.value, password: password.value}),
    }
  );

  useEffect(() => {
    if (!data) return;
    localStorage.setItem('userId', data.user_id);
    props.history.push('/');
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    exec();
  };

  return (
    <div>
      {showLoading && <LoadingPane/>}
      {!showLoading &&
          <div>
            <div>
              <form onSubmit={ handleSubmit }>
                <input type="text" name="email" placeholder="email" {...email}/>
                <input type="password" name="password" placeholder="password" {...password}/>
                <input type="submit" value="Login" disabled={loading}/>
                <a href="/register">Register</a>
              </form>
            </div>
            <div>{ error && <div className="error">{error}</div> }</div>
          </div>
      }
    </div>
  );
}

export default withRouter(Login);
