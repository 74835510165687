import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import LoadingPane from '../common/LoadingPane';
import { useFetch, useFormInput } from '../hooks';

function Settings(props) {
  document.title = 'Settings';

  const password = useFormInput('');
  const password2 = useFormInput('');
  const [error, setError] = useState('');

  let fetch = useFetch(
    'change_password',
    {
      method: 'POST',
      body: JSON.stringify({password: password.value}),
    }
  );

  if (fetch.error) {
    setError(fetch.error);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password.value) return;
    if (password.value !== password2.value) {
      setError('Password do not match');
      return;
    }
    await fetch.exec();
    props.history.push('/');
  };

  return (
    <div>
      {fetch.showLoading && <LoadingPane/>}
      {!fetch.showLoading &&
          <div className="outer">
            <Link to="/">Home</Link>
            <div>
              <form onSubmit={ handleSubmit }>
                <input type="password" name="password" placeholder="password" {...password}/>
                <input type="password" name="password2" placeholder="password repeated" {...password2}/>
                <input type="submit" value="Change password" disabled={fetch.loading}/>
              </form>
            </div>
            <div>{ error && <div className="error">{error}</div> }</div>
          </div>
      }
    </div>
  );
}

export default withRouter(Settings);
