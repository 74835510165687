import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useFormInput, useFetch, usePrevious } from '../hooks';
import './Header.css';

function Header(props) {
  const search = useFormInput('');
  const { exec } = useFetch('logout', {method: 'POST'});
  const prevStatus = usePrevious(props.status);

  useEffect(() => {
    if (prevStatus !== props.status) {
      search.onChange('');
    }
  });

  const handleSearch = (event) => {
    let keyCode = event.nativeEvent.keyCode;

    // ENTER
    if (keyCode === 13) {
      event.preventDefault();
      props.search(search.value);
    }
  };

  const handleLogout = async () => {
    await exec();
    localStorage.removeItem('userId');
    props.history.push('/login');
  };

  const { toggleSelectAll, bulkAction, toggleAddPage, status } = props;

  return (
    <div className="Header">
      <input type="text" name="search" placeholder="Search..."
        onKeyDown={ handleSearch } {...search} />
      <button name="select_all" onClick={ toggleSelectAll }>Select All</button>
      {
        !['later', 'archived'].includes(status) &&
        <button name="later" onClick={() => bulkAction('later') }>Later</button>
      }
      { status !== 'archived' &&
        <button name="archive" onClick={() => bulkAction('archive') }>Archive</button>
      }
      <button name="remove" onClick={() => bulkAction('remove') }>Remove</button>
      { (status === 'archived' || status === 'later' ) &&
        <button name="addPage" onClick={() => toggleAddPage()} >Add page</button>
      }
      <Link to="/feeds">Feeds</Link>
      <Link to="/settings">Settings</Link>
      <button name="logout" onClick={ handleLogout }>Logout</button>
    </div>
  );
}

export default withRouter(Header);
