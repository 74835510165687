import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Login from './login/Login';
import Register from './login/Register';
import Feeds from './feeds/Feeds';
import List from './list/List';
import Settings from './settings/Settings';
import NotFound from './notfound/NotFound';
import AdminLogin from './admin/AdminLogin';
import history from './history';
import './index.css';

const ServerStatus = lazy(() => import('./admin/ServerStatus'));

function App() {
  return (
    <Router history={history}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={List} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/feeds" component={Feeds} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/root/login" component={AdminLogin} />
          <Route exact path="/root" component={ServerStatus} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
